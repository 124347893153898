import { Market } from "@/clients";

const MANAGEMENT_FEES_BY_MARKET = {
  [Market.Standard]: 0.55,
  [Market.Sweden]: 0.4,
  [Market.Europe]: 0.59,
  [Market.Us]: 0.59,
  [Market.SwedenOld]: 0.4,
};

const EXCHANGE_FEE = 0.25;
const IDUN_INSURANCE_FEE = 0.2;
const TRANSACTION_FEE = 0.25;

export function getIdunInsuranceFee() {
  return IDUN_INSURANCE_FEE.toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function getManagementFeeForMarketFocus(market: Market | undefined): string {
  if (market !== undefined) {
    return MANAGEMENT_FEES_BY_MARKET[market].toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  return "";
}

export function getManagementFeeForMarketFocusWithoutVat(market: Market | undefined): string {
  if (market !== undefined) {
    return (MANAGEMENT_FEES_BY_MARKET[market] / 1.25).toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  return "";
}

export function getTransactionFeeWithoutVat(): string {
  return (TRANSACTION_FEE / 1.25).toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function getTransactionFee(): string {
  return (TRANSACTION_FEE).toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function getFxFee(): string {
  return (EXCHANGE_FEE).toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function getFxFeeWithoutVat(): string {
  return (EXCHANGE_FEE / 1.25).toLocaleString("sv-SE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export function getPriceGroup(): string {
  return "pricegroup_C";
}
