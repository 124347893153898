<template>
  <div class="overview">
    <SectionHeader
      v-show="$vuetify.display.mdAndUp"
      :header="$t('overview')"
      :sub-header="$t('yourPortfolios')"
    >
      <template #icon>
        <img :src="imgUrl">
      </template>
    </SectionHeader>
    <div class="overview__cards">
      <!-- Extra padding to account for the gradient -->
      <div
        v-if="portfolios && portfolios.length > 0"
        class="overview__portfolios"
      >
        <v-card
          v-for="portfolio in portfolios"
          :key="portfolio.id"
          class="overview__card overview__card--clickable"
          @click="$router.push({ name: 'portfolio', params: { id: portfolio.id } })"
        >
          <PortfolioSummary :portfolio-id="portfolio.id" />
        </v-card>
      </div>
      <v-card
        v-else
        class="overview__card"
      >
        <PortfolioListEmptyState />
      </v-card>
      <div class="overview__other-actions">
        <v-card
          v-if="!isCompany"
          :disabled="!answeredNewEsg"
          class="overview__card overview__card--clickable"
          @click="$router.push({ name: 'create-portfolio' })"
        >
          <CreatePortfolio />
        </v-card>
        <!-- <v-card
          v-if="!isCompany"
          :disabled="!answeredNewEsg"
          class="overview__card overview__card--clickable"
          @click="$router.push({ name: 'pension' })"
        >
          <MovePension />
        </v-card> -->
        <v-card
          v-if="!isCompany"
          class="overview__card overview__card--clickable"
          @click="showReferralsDialog"
        >
          <Referral />
        </v-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import PortfolioListEmptyState from "./portfolio-list-empty-state.vue";
import CreatePortfolio from "./create-portfolio.vue";
import PortfolioSummary from "./portfolio-summary.vue";
import { NavigationMutation } from "@/store/navigation/mutations";
// import MovePension from "@/components/move-pension.vue";
import Referral from "@/components/referral.vue";
import { UserMutation } from "@/store/user/mutations";
import { OverviewActions } from "@/store/overview/actions";
import SectionHeader from "@/components/section-header.vue";

export default defineComponent({
  components: {
    PortfolioListEmptyState,
    CreatePortfolio,
    // MovePension,
    Referral,
    PortfolioSummary,
    SectionHeader,
  },
  data() {
    return {
      imgUrl: new URL("/assets/icons/icon_overview_black.svg", import.meta.url).href,
    };
  },
  computed: {
    ...mapGetters(["isCompany", "answeredNewEsg"]),
    portfolios() {
      return this.$store.state.navigationStore.portfolios;
    },
  },
  created() {
    this.$store.dispatch(OverviewActions.loadCurrentLegalEntityPerformances);
  },
  mounted() {
    this.$store.commit(NavigationMutation.clearBackRoute);
  },
  methods: {
    showReferralsDialog() {
      this.$store.commit(UserMutation.setShowReferralsDialog, true);
    },
  },
});
</script>

<style lang="scss" scoped>
.overview {
  min-width: calc(100% - 1rem);
  max-width: 71rem;
  display: flex;
  flex-direction: column;
  margin-top: 0.2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  @include medium-up {
    min-width: 0;

    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  &__cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-row-gap: 1.5rem;
    @include medium-up {
      margin-top: 1.5rem;
      grid-template-columns: 1fr 22rem;
      grid-column-gap: 1.5rem;
    }
  }
  &__portfolios {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    @include medium-up {
      margin-right: auto;
      margin-bottom: 0;
      row-gap: 1.5rem;
    }
  }
  &__other-actions {
    min-width: calc(100% - 1rem);
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    @include medium-up {
      width: 100%;
      margin-right: auto;
      margin-bottom: 0;
      row-gap: 1.5rem;
    }
  }
  &__card--clickable {
    transition: background-color 0.2s ease-in-out;
    &:hover {
      background-color: $whisper;
    }
  }
  &__card {
    padding: 1rem;
    width: 100%;
  }
}

$gradients: (
  $soft-blue,
  $grayish-blue,
  $dusty-green,
  $soft-blue--darker,
  $nearly-black
);

.overview__portfolios {
  .overview__card {
    padding: 1rem 1rem 1rem 1.5rem;

    @for $i from 1 through 5 {
      &:nth-child(5n + #{$i}) {
        background: linear-gradient(
          to right,
          nth($gradients, $i),
          nth($gradients, $i) 0.55rem,
          #ffffff 0.55rem,
          #ffffff 100%
        );
      }
    }
  }
}
</style>
