import { baseUrl } from "./config";
import { ApplicationUsersClient } from ".";

const applicationUserClient = new ApplicationUsersClient(baseUrl);

export async function validateAffiliateCode(
  affiliateCode: string | undefined,
  affiliateName: string | undefined,
  isNaturalPerson: boolean,
): Promise<boolean> {
  return applicationUserClient.validateAffiliateCode(
    affiliateCode || null,
    affiliateName || null,
    isNaturalPerson,
  );
}

export async function validateSignupCode(code: string): Promise<boolean> {
  return code ? applicationUserClient.validateSignupCode(code) : false;
}
