import VuexPersistence from "vuex-persist";
import { createStore as _createStore } from "vuex";
import adminStore from "./admin";
import bankIdStore from "./bankid";
import companySignupStore from "./company-signup";
import navigationStore from "./navigation";
import overviewStore from "./overview";
import pensionSignupStore from "./pension";
import portfolioStore from "./portfolio";
import signupStore from "./signup";
import signupNavigationStore from "./signup-navigation";
import userStore from "./user";
import type { RootState } from "@/types";

const vuexSession = new VuexPersistence<RootState>({
  storage: window.sessionStorage,
  modules: ["signupStore", "companySignupStore", "signupNavigationStore", "pensionSignupStore"],
});

export default _createStore({ // TODO
  // Only enabled in dev. See https://vuex.vuejs.org/guide/strict.html
  // strict: import.meta.env.VITE_ENV === "development", // TODO Turn off

  modules: {
    adminStore,
    bankIdStore,
    companySignupStore,
    navigationStore,
    overviewStore,
    pensionSignupStore,
    portfolioStore,
    signupNavigationStore,
    signupStore,
    userStore,
  },
  plugins: [vuexSession.plugin],

});
