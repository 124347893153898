<template>
  <div class="progress-bar">
    <div
      v-for="index in numberOfParts"
      :key="index"
      :style="{
        width: `${getPartWidth(index)}rem`,
        marginRight: index < numberOfParts ? `${questionWidth * 0.5}rem` : 0,
      }"
      data-jest="progress-bar-part"
    >
      <v-progress-linear
        data-jest="progress-bar-inner-part"
        :data-jest-value="getPercentageOfQuestionCompleted(index)"
        :rounded="true"
        :height="progressBarHeight"
        :model-value="getPercentageOfQuestionCompleted(index)"
        bg-opacity="0.15"
        :color="colors?.accent"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import type { Colors } from "@/styles/colors";
import { softBlue } from "@/styles/colors";
import type { SignupState } from "@/store/signup/types";

export default defineComponent({
  data() {
    return {
      softBlue,
    };
  },
  computed: {
    ...mapGetters(["numberOfParts", "numberOfQuestionsPerPart"]),
    colors(): Colors | undefined {
      const { colors } = this.$store.state.signupStore as SignupState;
      return colors || undefined;
    },
    currentPart(): number {
      return this.$store.state.signupNavigationStore.questionProgress.partNumber;
    },
    percentageOfCurrentPart(): number {
      return (
        100
        * (this.$store.state.signupNavigationStore.questionProgress.questionNumber
        / this.numberOfQuestionsPerPart[this.currentPart])
      );
    },
    progressBarHeight(): number {
      return this.questionWidth * 8; // In px
    },
    questionWidth(): number {
      return this.$vuetify.display.xl ? 0.75 : 0.5;
    },
  },
  methods: {
    getPartWidth(part: number): number {
      return this.numberOfQuestionsPerPart[part] * this.questionWidth;
    },
    getPercentageOfQuestionCompleted(part: number): number {
      if (part < this.currentPart) {
        return 100;
      }
      if (part > this.currentPart) {
        return 0;
      }
      return Math.min(this.percentageOfCurrentPart, 100);
    },
  },
});
</script>

<style lang="scss" scoped>
.progress-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
