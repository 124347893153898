import type { ActionTree } from "vuex";
import type { PdfMode } from "../signup-navigation/types";
import { SignupMutation } from "../signup/mutations";
import { UserMutation } from "../user/mutations";
import { BankIdMutation } from "../bankid/mutations";
import { PensionSignupMutation } from "./mutations";
import type { PensionSignupState } from "./types";
import type {
  CreateMultiplePortfoliosResult,
  FileResponse,
  KnowYourCustomerAnswers,
  // StartSearchResponse,
} from "@/clients";
import {
  Client,
  InsuranceClient,
  PdfClient,
  PensionInvestmentStrategyRequest,
  PensionRiskEvaluationRequest,
  RiskClient,
} from "@/clients";
import { baseUrl } from "@/clients/config";
import type { RootState } from "@/types";

import { constants } from "@/config/general";
import {
  getCreatePensionParameters,
  getCreatePensionParametersFirst,
} from "@/mappers/create-pension-parameters";
import type { RiskWillingness } from "@/types/signup";
import { downloadFileResponse } from "@/utils/downloader";
import { createPollingPromise } from "@/utils/polling-promise";

const insuranceClient = new InsuranceClient(baseUrl);
const portfoliosClient = new Client(baseUrl);
const riskClient = new RiskClient(baseUrl);
const pdfClient = new PdfClient(baseUrl);

export const PensionSignupAction = {
  startInsuranceSearch: "startInsuranceSearch",
  pollInsuranceSearchStatus: "pollInsuranceSearchStatus",
  cancelAllInsuranceSearchStatusPolls: "cancelAllInsuranceSearchStatusPolls",
  getInsuranceData: "getInsuranceData",
  prepareCreatePensionPortfolios: "prepareCreatePensionPortfolios",
  completeCreatePensionPortfolios: "completeCreatePensionPortfolios",
  prepareCreatePensionsFirst: "prepareCreatePensionsFirst",
  completeCreatePensionsFirst: "completeCreatePensionsFirst",
  getCompanyStatuses: "getCompanyStatuses",
  evaluatePensionRiskProfile: "evaluatePensionRiskProfile",
  getPortfolioManagementPdfPensionSignup: "getPortfolioManagementPdfPensionSignup",
  getPortfolioInvestmentStrategyPdfPensionSignup: "getPortfolioInvestmentStrategyPdfPensionSignup",
  getIdunPersonKycPdf: "getIdunPersonKycPdf",
};

export const actions: ActionTree<PensionSignupState, RootState> = {
  async [PensionSignupAction.startInsuranceSearch](
    { commit, rootState },
    payload: { insuranceCompanyId: string; isMobile: boolean },
  ): Promise<void> {
    /*
      const legalEntity = rootState.userStore.currentLegalEntity;
      try {
        let response: StartSearchResponse | null;

        commit(PensionSignupMutation.addCompanyInfo, payload.insuranceCompanyId);
        commit(PensionSignupMutation.setPensionCollectionStatus, {
          insuranceCompanyId: payload.insuranceCompanyId,
          status: "RUNNING",
        });
        if (import.meta.env.VITE_ENV === "production") {
          response = await insuranceClient.startSearch(
            legalEntity?.brickId as string,
            payload.insuranceCompanyId,
            payload.isMobile,
          );
        } else {
          response = await insuranceClient.startSearchDev(
            legalEntity?.brickId as string,
            "199303082573", // state.testUserPersonalIdentityNumber as string,
            payload.insuranceCompanyId,
            payload.isMobile,
          );
        }

        if (response) {
          commit(PensionSignupMutation.setCollectionId, {
            insuranceCompanyId: payload.insuranceCompanyId,
            insurelyCollectionId: response?.id,
          });
        }
      } catch (error: any) {
        console.error(error);
        commit(PensionSignupMutation.setPensionCollectionStatus, {
          insuranceCompanyId: payload.insuranceCompanyId,
          status: "FAILED",
        });
        throw error;
      }
      return Promise.resolve();
    */
  },
  async [PensionSignupAction.pollInsuranceSearchStatus](
    { state, commit, rootState },
    insuranceCompanyId: string,
  ): Promise<boolean | void> {
    const legalEntity = rootState.userStore.currentLegalEntity;
    const collectionId = state.companyInfo[insuranceCompanyId].insurelyCollectionId;
    if (!collectionId) {
      throw new Error("Collectionid not found");
    }
    return createPollingPromise(
      async () => {
        if (state.companyInfo[insuranceCompanyId].pollingCancelled) {
          return Promise.reject();
        }

        const response = await insuranceClient.getPensionSearchResults(
          legalEntity?.brickId as string,
          collectionId,
        );

        if (!response) {
          return Promise.resolve(true);
        }
        commit(PensionSignupMutation.setPensionCollectionStatus, {
          insuranceCompanyId,
          status: response.status,
        });
        commit(PensionSignupMutation.setPensionCollectionExtraInformation, {
          insuranceCompanyId,
          extraInformation: response.extraInformation,
        });
        if (
          (response.pensionData && response.pensionData.length > 0)
          || response.status === "COMPLETED EMPTY"
          || response.status === "COMPLETED PARTIAL"
        ) {
          commit(PensionSignupMutation.addSelectablePension, response.pensionData);
        }

        return Promise.resolve(
          response.status !== "RUNNING"
          && response.status !== "COLLECTING"
          && response.status !== "LOGIN"
          && response.status !== "CONTACT FORM PENDING",
        );
      },
      constants.insurelyStatusPollingDelay,
      undefined,
      undefined,
      true,
      120,
    ).catch(_ =>
      commit(PensionSignupMutation.setPensionCollectionStatus, {
        insuranceCompanyId,
        status: "TIMEOUT", // In practice a caught error here can mean many things, but usually it'll be timeout
      }),
    );
  },
  async [PensionSignupAction.cancelAllInsuranceSearchStatusPolls]({
    state,
    commit,
  }): Promise<void> {
    commit(PensionSignupMutation.cancelPensionPolling);
  },
  async [PensionSignupAction.prepareCreatePensionPortfolios]({
    state,
    commit,
    getters,
    rootState,
  }): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }

    const createPensionParameters = getCreatePensionParameters(state, getters, rootState.signupStore);

    try {
      const response = await portfoliosClient.postApiLegalEntitiesPortfoliosPensionPrepare(
        rootState.userStore.currentLegalEntity.brickId,
        createPensionParameters,
      );
      if (!response) {
        throw new Error("Failed to prepare create pension portfolios");
      }
      commit(BankIdMutation.setTransactionId, response?.id);
      commit(UserMutation.setAgreementsToSign, response);
    } catch (error: any) {
      console.error(error);
      throw new Error("Failed to prepare create pension portfolios");
    }
  },
  async [PensionSignupAction.completeCreatePensionPortfolios]({
    state,
    commit,
    getters,
    rootState,
  }): Promise<CreateMultiplePortfoliosResult | null> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!rootState.bankIdStore.transactionId) {
      throw new Error("No current legal entity in state");
    }
    try {
      const response = await portfoliosClient.postApiLegalEntitiesPortfoliosPensionComplete(
        rootState.userStore.currentLegalEntity.brickId,
        rootState.bankIdStore.transactionId,
      );
      if (!response) {
        throw new Error("Failed to create pension portfolios");
      }
      commit(UserMutation.setToken, response.token);
      return response;
    } catch (error: any) {
      console.error(error);
      throw new Error("Failed to create pension portfolios");
    }
  },
  async [PensionSignupAction.prepareCreatePensionsFirst]({
    state,
    commit,
    getters,
    rootState,
  }): Promise<void> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }

    const createPensionParameters = getCreatePensionParametersFirst(
      state,
      rootState.signupStore,
      getters,
    );

    try {
      const response = await portfoliosClient.postApiLegalEntitiesPortfoliosPensionPrepareFirst(
        rootState.userStore.currentLegalEntity.brickId,
        createPensionParameters,
      );
      if (!response) {
        throw new Error("Failed to prepare create pension portfolios");
      }
      commit(BankIdMutation.setTransactionId, response?.id);
      commit(UserMutation.setAgreementsToSign, response);
    } catch (error: any) {
      console.error(error);
      throw new Error("Failed to prepare create pension portfolios");
    }
  },
  async [PensionSignupAction.completeCreatePensionsFirst]({
    commit,
    rootState,
  }): Promise<CreateMultiplePortfoliosResult | null> {
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    if (!rootState.bankIdStore.transactionId) {
      throw new Error("No current legal entity in state");
    }
    try {
      const response = await portfoliosClient.postApiLegalEntitiesPortfoliosPensionCompleteFirst(
        rootState.userStore.currentLegalEntity.brickId,
        rootState.bankIdStore.transactionId,
      );
      if (!response) {
        throw new Error("Failed to create pension portfolios");
      }
      commit(UserMutation.setToken, response.token);
      commit(UserMutation.setLegalEntity, response.legalEntity);

      return response;
    } catch (error: any) {
      console.error(error);
      throw new Error("Failed to create pension portfolios");
    }
  },
  async [PensionSignupAction.getCompanyStatuses]({ commit, rootState }): Promise<void> {
    const legalEntity = rootState.userStore.currentLegalEntity;
    try {
      const response = await insuranceClient.getCompanyStatus(legalEntity?.brickId as string);
      if (response) {
        commit(PensionSignupMutation.setCompanyStatuses, response);
      }
    } catch (error: any) {
      console.error(error);
    }
  },
  async [PensionSignupAction.evaluatePensionRiskProfile]({
    state,
    commit,
    rootState,
  }): Promise<void> {
    commit(PensionSignupMutation.setCalculatedRiskProfile, undefined);
    const legalEntity = rootState.userStore.currentLegalEntity;
    if (!legalEntity) {
      throw new Error("No legal entity in state");
    }
    try {
      const response = await riskClient.evaluatePensionRiskProfile(
        legalEntity?.brickId as string,
        new PensionRiskEvaluationRequest({
          payoutTime: state.payoutTime as number,
          chosenRiskWillingness:
            legalEntity.chosenRiskWillingness !== undefined
              && legalEntity.chosenRiskWillingness !== null
              ? (legalEntity.chosenRiskWillingness as RiskWillingness)
              : (rootState.signupStore.riskWillingness as RiskWillingness),
          otherOccupationalPensionSavings: state.hasOtherOccupationalPensionSavings as boolean,
          privatePensionSavings: state.hasPrivatePensionSavings as boolean,
          totalSavings:
            legalEntity.totalSavings !== undefined && legalEntity.totalSavings !== null
              ? legalEntity.totalSavings
              : rootState.signupStore.totalSavings,
          totalLoans:
            legalEntity.totalLoans !== undefined && legalEntity.totalLoans !== null
              ? legalEntity.totalLoans
              : rootState.signupStore.totalLoans,
          boundCapital:
            legalEntity.boundCapital !== undefined && legalEntity.boundCapital !== null
              ? legalEntity.boundCapital
              : rootState.signupStore.boundCapital,
        }),
      );
      if (response) {
        commit(PensionSignupMutation.setCalculatedRiskProfile, response.riskProfile);
      }
    } catch (error: any) {
      console.error(error);
    }
  },
  async [PensionSignupAction.getPortfolioManagementPdfPensionSignup](
    { commit, rootState },
    { pdfId, pdfMode }: { pdfId: number; pdfMode: PdfMode },
  ): Promise<void> {
    commit(SignupMutation.setLoadingPdf, { pdfId, loading: true });
    const legalEntity = rootState.userStore.currentLegalEntity;

    await pdfClient
      .getPortfolioManagementPensionPdf(legalEntity?.brickId as string)
      .then((response: FileResponse) => {
        downloadFileResponse(response);
        commit(SignupMutation.setLoadingPdf, { pdfId, loading: false });
      })
      .catch((_) => {
        commit(SignupMutation.setLoadingPdf, { pdfId, loading: false });
      });
  },
  async [PensionSignupAction.getPortfolioInvestmentStrategyPdfPensionSignup](
    { state, commit, getters, rootState },
    { pdfId }: { pdfId: number },
  ): Promise<void> {
    commit(SignupMutation.setLoadingPdf, { pdfId, loading: true });
    if (!rootState.userStore.currentLegalEntity) {
      throw new Error("No current legal entity in state");
    }
    const parameters = getCreatePensionParameters(state, getters, rootState.signupStore);

    // rootState.userStore.currentLegalEntity?.brickId as string,
    await pdfClient
      .getPortfolioInvestmentStrategyPensionPdf(
        rootState.userStore.currentLegalEntity.brickId,
        new PensionInvestmentStrategyRequest({
          legalEntity: getters.signupLegalEntity,
          strategy: parameters.strategy,
          hasOtherOccupationalPensionSavings: state.hasOtherOccupationalPensionSavings as boolean,
          hasPrivatePensionSavings: state.hasPrivatePensionSavings as boolean,
        }),
      )
      .then((response: FileResponse) => {
        downloadFileResponse(response);
        commit(SignupMutation.setLoadingPdf, { pdfId, loading: false });
      })
      .catch((_) => {
        commit(SignupMutation.setLoadingPdf, { pdfId, loading: false });
      });
  },
  async [PensionSignupAction.getIdunPersonKycPdf](
    { commit, rootState, getters },
    { pdfId }: { pdfId: number },
  ): Promise<void> {
    commit(SignupMutation.setLoadingPdf, { pdfId, loading: true });
    const legalEntityId = rootState.userStore.currentLegalEntity?.brickId;
    let kyc: KnowYourCustomerAnswers;
    if (!getters.signupKyc || !legalEntityId) {
      throw new Error("No current legal entity in state");
    }
    if (getters.signupKyc.pep === undefined) {
      kyc = rootState.userStore.currentLegalEntity!.knowYourCustomerAnswers!;
    } else {
      kyc = getters.signupKyc;
    }
    await pdfClient
      .getIdunPersonKycPdf(legalEntityId, kyc)
      .then((response: FileResponse) => {
        downloadFileResponse(response);
        commit(SignupMutation.setLoadingPdf, { pdfId, loading: false });
      })
      .catch((_) => {
        commit(SignupMutation.setLoadingPdf, { pdfId, loading: false });
      });
  },
};
