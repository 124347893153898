<template>
  <div class="welcome">
    <div class="welcome__logo-container">
      <div class="welcome__logo-wrapper">
        <MainLogo :tied-agent="tiedAgent" />
      </div>
    </div>
    <SingleChoice
      class="welcome__single-choice"
      :alternatives="alternatives"
      @click="buttonClick"
    >
      <InformationTextSection
        invisible-image
        :heading="$t('welcome')"
        :body="$t('signup.questions.welcome.body')"
      />
    </SingleChoice>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SingleChoice from "../templates/single-choice.vue";
import InformationTextSection from "../information-text-section.vue";
import Question from "../templates/question";
import { SignupNavigationAction } from "@/store/signup-navigation/actions";
import type { TiedAgent } from "@/clients";
import { LogLevel } from "@/clients";
import { trackEvent, trackEventOnlyGa4 } from "@/clients/segment";
import log from "@/clients/log";
import MainLogo from "@/components/main-logo.vue";

const logger = "welcome.vue";

export default defineComponent({
  components: {
    InformationTextSection,
    SingleChoice,
    MainLogo,
  },
  extends: Question,
  data(): any {
    return {
      logoUrl: new URL("/assets/images/logos/sigmastocks-logo-text.svg", import.meta.url).href,
    };
  },
  computed: {
    tiedAgent(): TiedAgent {
      return this.$store.state.signupStore.tiedAgent;
    },
    alternatives() {
      return [
        {
          text: this.$t("next"),
          key: "next",
        },
      ];
    },
  },
  mounted() {
    log(logger, "ad_start_signup", LogLevel.Information);
    trackEvent("start_signup");
    trackEventOnlyGa4("conversion", { send_to: "AW-966604819/OhNBCNCExNECEJPw9MwD" });
  },

  methods: {
    buttonClick() {
      this.$store.dispatch(SignupNavigationAction.goToNextStep, this.$router);
    },
    goToCompanySignup() {
      // Use replace otherwise the router will not work if the user clicks the back button in the browser.
      // This way the back button leads to the start view (/signup).
      this.$router.replace({ name: "company-signup" });
    },
  },
});
</script>

<style lang="scss" scoped>
.welcome {
  height: 100%;
  display: flex;
  flex-direction: column;
  @include medium-up {
    max-height: $signup-desktop-max-height;
  }
  &__single-choice {
    padding-bottom: 1rem;
  }
  &__logo-container {
    margin-top: 1rem;
    height: 3rem;
    display: block;
    margin-top: 1rem;
  }
  &__logo {
    path {
      fill: $soft-blue;
      opacity: 1;
    }
  }
  &__logo-wrapper {
    width: 100%;
    display: inline-block;
    text-align: center;
  }
  &__create-account-text {
    color: $nearly-black;
    opacity: 0.63;
    padding-right: 0.25rem;
  }
}
</style>
