<template>
  <div class="paused">
    <div class="paused__logo-container">
      <div class="paused__logo-wrapper">
        <MainLogo :tied-agent="tiedAgent" />
      </div>
    </div>
    <SingleChoice
      class="paused__single-choice"
      :alternatives="alternatives"
      @click="buttonClick"
    >
      <InformationTextSection
        invisible-image
        heading="Just nu tar vi inte emot några nya kunder."
        body="Detta med anledning av Avanzas förvärv av Sigmastocks. Mer information kommer framöver!"
        body2="För dig med lite större sparkapital hör av dig till oss på support@sigmastocks.com."
      />
    </SingleChoice>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import InformationTextSection from "./information-text-section.vue";
import Question from "./templates/question";
import SingleChoice from "./templates/single-choice.vue";
import type { TiedAgent } from "@/clients";
import MainLogo from "@/components/main-logo.vue";

export default defineComponent({
  components: {
    InformationTextSection,
    SingleChoice,
    MainLogo,
  },
  extends: Question,
  data(): any {
    return {
      logoUrl: new URL("/assets/images/logos/sigmastocks-logo-text.svg", import.meta.url).href,
    };
  },
  computed: {
    tiedAgent(): TiedAgent {
      return this.$store.state.signupStore.tiedAgent;
    },
    alternatives() {
      return [
        {
          text: this.$t("back"),
          key: "back",
        },
      ];
    },
  },
  methods: {
    buttonClick() {
      // While signup is closed, redirect away on back.
      window.location.href = "https://www.sigmastocks.com/";
    },
  },
});
</script>

<style lang="scss" scoped>
.paused {
  height: 100%;
  display: flex;
  flex-direction: column;

  @include medium-up {
    max-height: $signup-desktop-max-height;
  }

  &__single-choice {
    padding-bottom: 1rem;
  }

  &__logo-container {
    margin-top: 1rem;
    height: 3rem;
    display: block;
    margin-top: 1rem;
  }

  &__logo-wrapper {
    width: 100%;
    display: inline-block;
    text-align: center;
  }
}
</style>
